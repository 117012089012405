// get_ fake host

export const GET_FAKE_STORY = "GET_FAKE_STORY";

// fake host dialog open
export const OPEN_FAKE_STORY_DIALOGUE = "OPEN_FAKE_STORY_DIALOGUE";

// close fake STORY
export const CLOSE_FAKE_STORY_DIALOGUE = "CLOSE_FAKE_STORY_DIALOGUE";

//  create fake STORY
export const CREATE_FAKE_STORY = "CREATE_FAKE_STORY";

// update fake STORY
export const UPDATE_FAKE_STORY = "UPDATE_FAKE_STORY";

// delete fake STORY
export const DELETE_FAKE_STORY = "DELETE_FAKE_STORY";



